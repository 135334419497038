import { withPageAuthRequired } from '@auth0/nextjs-auth0/client'
import { GetServerSideProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { CredentialContainer } from '@/components/templates/credential'

export const getServerSideProps: GetServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale!, ['common'])),
  },
})
const Credentials: NextPage = () => {
  return <CredentialContainer />
}

export default withPageAuthRequired(Credentials)

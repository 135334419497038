import styled from '@emotion/styled'
import { FC } from 'react'
import { CredentialListView } from './CredentialListView'
import { useCredentials } from '@/hooks/useCredentials'

export const CredentialContainer: FC = () => {
  const { defaultCredItems, collections, isInitialLoading, isInitialLoadingCollecion } =
    useCredentials()

  return (
    <CredentialListView
      collections={collections}
      credentials={defaultCredItems?.items}
      isLoading={isInitialLoading || isInitialLoadingCollecion}
    />
  )
}
